// Generated by Framer (2372734)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, RichText, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["qLdr7xyIh"];

const variantClassNames = {qLdr7xyIh: "framer-v-v8ikhf"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, title, width, ...props}) => { return {...props, X9G9nk_6M: title ?? props.X9G9nk_6M ?? "Click"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;title?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, X9G9nk_6M, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "qLdr7xyIh", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-xdvgm", classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.button {...restProps} className={cx("framer-v8ikhf", className)} data-framer-name={"Variant 1"} data-reset={"button"} layoutDependency={layoutDependency} layoutId={"qLdr7xyIh"} ref={ref} style={{backgroundColor: "rgb(0, 153, 255)", borderBottomLeftRadius: 10, borderBottomRightRadius: 10, borderTopLeftRadius: 10, borderTopRightRadius: 10, ...style}}><RichText __fromCanvasComponent children={<React.Fragment><motion.p><motion.span style={{"--font-selector": "R0Y7RE0gU2Fucy01MDA=", "--framer-font-family": "\"DM Sans\", sans-serif", "--framer-font-size": "20px", "--framer-font-weight": "500", "--framer-text-color": "var(--extracted-1w3ko1f)"}}>Click</motion.span></motion.p></React.Fragment>} className={"framer-uhvtg6"} fonts={["GF;DM Sans-500"]} layoutDependency={layoutDependency} layoutId={"hdKEHISc7"} style={{"--extracted-1w3ko1f": "rgb(255, 255, 255)", "--framer-paragraph-spacing": "0px"}} text={X9G9nk_6M} verticalAlignment={"top"} withExternalLayout/></motion.button></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-xdvgm [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-xdvgm .framer-tzzexd { display: block; }", ".framer-xdvgm .framer-v8ikhf { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 8px; height: min-content; justify-content: center; padding: 10px 20px 10px 20px; position: relative; width: min-content; }", ".framer-xdvgm .framer-uhvtg6 { flex: none; height: auto; overflow: visible; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-xdvgm .framer-v8ikhf { gap: 0px; } .framer-xdvgm .framer-v8ikhf > * { margin: 0px; margin-left: calc(8px / 2); margin-right: calc(8px / 2); } .framer-xdvgm .framer-v8ikhf > :first-child { margin-left: 0px; } .framer-xdvgm .framer-v8ikhf > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 44
 * @framerIntrinsicWidth 86
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]}}}
 * @framerVariables {"X9G9nk_6M":"title"}
 */
const FramerMknh9yrRZ: React.ComponentType<Props> = withCSS(Component, css, "framer-xdvgm") as typeof Component;
export default FramerMknh9yrRZ;

FramerMknh9yrRZ.displayName = "Button";

FramerMknh9yrRZ.defaultProps = {height: 44, width: 86};

addPropertyControls(FramerMknh9yrRZ, {X9G9nk_6M: {defaultValue: "Click", displayTextArea: false, title: "Title", type: ControlType.String}} as any)

addFonts(FramerMknh9yrRZ, [{family: "DM Sans", moduleAsset: {localModuleIdentifier: "local-module:canvasComponent/Mknh9yrRZ:default", url: "https://fonts.gstatic.com/s/dmsans/v14/rP2tp2ywxg089UriI5-g4vlH9VoD8CmcqZG40F9JadbnoEwAkJxhTmf3ZGMZpg.ttf"}, style: "normal", url: "https://fonts.gstatic.com/s/dmsans/v14/rP2tp2ywxg089UriI5-g4vlH9VoD8CmcqZG40F9JadbnoEwAkJxhTmf3ZGMZpg.ttf", weight: "500"}])